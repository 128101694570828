<template>
  <div class="container d-flex flex-column align-items-center mt-5">
    <h2 class="text-white text-center my-5">
      绑定商家
    </h2>
    <h2 v-if="alreadyBinded" class="text-white text-center my-5">
      已经绑定
      <span v-if="tokenParam.merchant_key === 1">YesSkins</span>
      <span v-if="tokenParam.merchant_key === 2">DeepCase</span>
      账号！
      <fa class="text-primary" :icon="['fa', 'info-circle']" />
    </h2>
    <h2 v-if="stage === 'success' && !alreadyBinded" class="text-white text-center my-5">
      绑定成功！
      <fa class="text-primary" :icon="['fa', 'check']" />
    </h2>
    <h2 v-if="stage === 'fail'" class="text-white text-center my-5">
      绑定失败
      <fa class="text-danger ml-2" :icon="['far', 'times-circle']" />
    </h2>
    <div v-if="loading">
      <h2 class="text-white text-center my-5">绑定中...</h2>
      <spinner />
    </div>

    <router-link v-if="stage === 'success' || alreadyBinded" to="/user/inventories">
      <btn class="btn-outline-primary">
        去提取库存商品
      </btn>
    </router-link>
    <btn v-if="stage === 'fail' && !alreadyBinded" class="btn-outline-primary" @click="retry">
      重试
    </btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { UserService } from '@/services/api'
import { errorParser } from '@/utils'

export default {
  name: 'Bind',
  data() {
    return {
      token: '',
      merchantKey: null,
      loading: false,
      stage: 'unset',
      alreadyBinded: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
    boundMerchant() {
      return this.user.info?.bound_merchant
    },
    tokenParam() {
      let t, key
      if (this.$router.currentRoute.query?.ytoken) {
        t = this.$router.currentRoute.query?.ytoken
        key = 1
      } else if (this.$router.currentRoute.query?.dtoken) {
        t = this.$router.currentRoute.query?.dtoken
        key = 2
      } else if (this.$router.currentRoute.query?.yctoken) {
        t = this.$router.currentRoute.query?.yctoken
        key = 3
      } else {
        return null
      }
      return {
        merchant_token: t,
        merchant_key: key,
      }
    },
  },
  mounted() {
    if (this.boundMerchant && this.boundMerchant.length > 0) {
      const a = this.boundMerchant.find(m => m.merchant_key === this.tokenParam.merchant_key)
      if (a) {
        this.alreadyBinded = true
      }
    } else {
      if (!this.tokenParam) {
        this.toast('缺少token')
      } else {
        this.bindToken()
      }
    }
  },
  methods: {
    toast(message) {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        variant: 'warning',
        solid: true,
      })
    },
    bindToken() {
      const params = this.tokenParam
      this.loading = true
      UserService.merchantBind(params)
        .then(() => {
          this.stage = 'success'
        })
        .catch(e => {
          this.stage = 'fail'
          this.toast(errorParser(e.response).message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    retry() {
      this.stage = 'unset'
      this.bindToken()
    },
  },
}
</script>

<style lang="scss" scoped>
.bind {
  margin: 100px;
}
</style>
